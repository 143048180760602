.layout {
  width: 100%;
  min-height: 100vh;

  .header {
    width: 100%;
    height: 64px;
    background-color: #fff;
    position: fixed;
    z-index: 1;
    transition: box-shadow 0.5s ease-in-out;
  }

  .shadow {
    box-shadow: 1px 1px 3px #eaeaea;
  }

  .noShadow {
    box-shadow: none;
  }

  .content {
    width: 100%;
    background-color: #fff;
  }

  .mobileOnly {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    display: none;
  }

  .mobileOnly {
    display: block;
  }
}

