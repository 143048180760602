.container{
	position: relative;
  width: 100%;
	min-height: calc(100vh - 64px);
  margin-top: 64px;

  .title {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 1.23;
  }

  .desc {
    text-align: justify;
  }

  .location {
    margin-bottom: 1em;

    * {
      margin-right: 10px;
    }
  }

  .linkContainer {
    margin-top: 30px;

    .linkIcon {
      text-decoration: none;
      color: #4f4f4f;
      margin-right: 10px;

      * {
        margin-right: 10px;
      }
    }
  }

  .photoContainer {
    text-align: center;

    img {
      border-radius: 50%;
      max-width: 250px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container{
    min-height: 100vh;
    margin-top: 0px;

    .location {
      display: none;
    }

    .photoContainer img{
      width: 70%;
    }
  }
}
