.container {
  width: 100%;
  height: 100%;

  .navigationMenu {
    border-bottom: none;

    * {
      font-weight: 600;
    }
  }

}
