.container{
  position: relative;
  background-color: #FFA500;

  .wrapper {
    width: 100%;
    padding: 10px;
  }

  .linkIconFooter {
    color: #fff;
  }

  .social {
    text-align: center;
    font-weight: 700;
  }

  .linkIconWrapper {
    text-align: center;
  }

  .linkIconFooter {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-decoration: none;
    color: #fff;
    font-size: 2rem;
  }
}