.container{
  position: relative;
  width: 100%;
  min-height: calc(100vh - 64px);
  padding: 50px 0px;
  background-color: #fbfbfb;

  .wrapper {
    width: 100%;
  }

  .title {
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 1.23;
    text-align: end;
  }

  .secondTitle {
    color: #4f4f4f;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 1.23;
    text-align: center;
    font-weight: 700;
  }

  .social {
    text-align: center;
    font-weight: 700;
  }

  .linkIcon {
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: #4f4f4f;

    * {
      width: 100%;
      text-align: center;
      font-size: 48px;
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container{
    padding-top: 50px;
  }
}