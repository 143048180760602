@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700&display=swap);
* {
  font-family: 'Nunito Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0px;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 10px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 1.5rem;
  }

}

.navigation-bar_container__2UipO{width:100%;height:100%}.navigation-bar_container__2UipO .navigation-bar_navigationMenu__1tljF{border-bottom:none}.navigation-bar_container__2UipO .navigation-bar_navigationMenu__1tljF *{font-weight:600}

.profile_container__1aGTu{position:relative;width:100%;min-height:calc(100vh - 64px);margin-top:64px}.profile_container__1aGTu .profile_title__1lt4V{margin-bottom:0.5em;color:rgba(0,0,0,0.85);font-weight:600;font-size:2.8rem;line-height:1.23}.profile_container__1aGTu .profile_desc__2JdGg{text-align:justify}.profile_container__1aGTu .profile_location__3JmV7{margin-bottom:1em}.profile_container__1aGTu .profile_location__3JmV7 *{margin-right:10px}.profile_container__1aGTu .profile_linkContainer__YQqvc{margin-top:30px}.profile_container__1aGTu .profile_linkContainer__YQqvc .profile_linkIcon__2n4Qm{text-decoration:none;color:#4f4f4f;margin-right:10px}.profile_container__1aGTu .profile_linkContainer__YQqvc .profile_linkIcon__2n4Qm *{margin-right:10px}.profile_container__1aGTu .profile_photoContainer__1dDwT{text-align:center}.profile_container__1aGTu .profile_photoContainer__1dDwT img{border-radius:50%;max-width:250px;width:100%}@media only screen and (max-width: 768px){.profile_container__1aGTu{min-height:100vh;margin-top:0px}.profile_container__1aGTu .profile_location__3JmV7{display:none}.profile_container__1aGTu .profile_photoContainer__1dDwT img{width:70%}}

.about_container__2Vjxi{position:relative;width:100%;min-height:calc(100vh - 64px);padding:50px 0px;background-color:#fbfbfb}.about_container__2Vjxi .about_wrapper__3Tmmy{width:100%}.about_container__2Vjxi .about_title__1QfJm{margin-bottom:1rem;color:rgba(0,0,0,0.85);font-weight:600;font-size:2.8rem;line-height:1.23}.about_container__2Vjxi .about_desc__UiQSv{margin-top:16px}.about_container__2Vjxi .about_frontend__21Lj6{text-align:left}.about_container__2Vjxi .about_backend__bvyAL{text-align:right}.about_container__2Vjxi .about_other__3ZEHq{text-align:center}@media only screen and (max-width: 768px){.about_container__2Vjxi{padding-top:50px}}

.projects_container__3Krwb{position:relative;width:100%;min-height:calc(100vh - 64px);padding:50px 0px}.projects_container__3Krwb .projects_wrapper__3l8rG{width:100%}.projects_container__3Krwb .projects_wrapper__3l8rG .projects_title__3PjPo{margin-bottom:1rem;color:rgba(0,0,0,0.85);font-weight:600;font-size:2.8rem;line-height:1.23;text-align:center}@media only screen and (max-width: 768px){.projects_container__3Krwb{padding-top:50px}}

.contact_container__1fmcT{position:relative;width:100%;min-height:calc(100vh - 64px);padding:50px 0px;background-color:#fbfbfb}.contact_container__1fmcT .contact_wrapper__2LaYa{width:100%}.contact_container__1fmcT .contact_title__hJqjW{margin-bottom:1rem;color:rgba(0,0,0,0.85);font-weight:600;font-size:2.8rem;line-height:1.23;text-align:end}.contact_container__1fmcT .contact_secondTitle__3Egtx{color:#4f4f4f;margin-bottom:35px;font-weight:600;font-size:2.8rem;line-height:1.23;text-align:center;font-weight:700}.contact_container__1fmcT .contact_social__2EERv{text-align:center;font-weight:700}.contact_container__1fmcT .contact_linkIcon__2-32D{width:100%;text-align:center;text-decoration:none;color:#4f4f4f}.contact_container__1fmcT .contact_linkIcon__2-32D *{width:100%;text-align:center;font-size:48px;margin-bottom:15px}@media only screen and (max-width: 768px){.contact_container__1fmcT{padding-top:50px}}

.social_container__HSEmz{position:relative;background-color:#FFA500}.social_container__HSEmz .social_wrapper__OO7Hc{width:100%;padding:10px}.social_container__HSEmz .social_linkIconFooter__32X-O{color:#fff}.social_container__HSEmz .social_social__1GkXR{text-align:center;font-weight:700}.social_container__HSEmz .social_linkIconWrapper__1dijo{text-align:center}.social_container__HSEmz .social_linkIconFooter__32X-O{width:100%;height:100%;vertical-align:middle;text-decoration:none;color:#fff;font-size:2rem}

.home_layout__3c12V{width:100%;min-height:100vh}.home_layout__3c12V .home_header__24EeC{width:100%;height:64px;background-color:#fff;position:fixed;z-index:1;transition:box-shadow 0.5s ease-in-out}.home_layout__3c12V .home_shadow__wFg-N{box-shadow:1px 1px 3px #eaeaea}.home_layout__3c12V .home_noShadow__2Kja8{box-shadow:none}.home_layout__3c12V .home_content__g5umJ{width:100%;background-color:#fff}.home_layout__3c12V .home_mobileOnly__3_oEG{display:none}@media only screen and (max-width: 768px){.home_header__24EeC{display:none}.home_mobileOnly__3_oEG{display:block}}

