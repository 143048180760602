.container{
  position: relative;
  width: 100%;
  min-height: calc(100vh - 64px);
  padding: 50px 0px;

  .wrapper {
    width: 100%;

    .title {
      margin-bottom: 1rem;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 2.8rem;
      line-height: 1.23;
      text-align: center;
    }
  }

}

@media only screen and (max-width: 768px) {
  .container{
    padding-top: 50px;
  }
}